<template>
  <main id="TopSection">
    <!--<div class="TopSection">--><a name="Top"></a>
    <h1><span class="inline animate__animated animate__fadeInLeft">Percy</span><span class="animate__animated animate__fadeIn">.</span><span class="animate__animated animate__fadeInRight">Tech</span></h1>
    <!-- <ul>
      <li class="aboutList"><a @click="smoothScrollToSection('About')">About</a></li>
      <li class="projectList"><a @click="smoothScrollToSection('Projects')">Projects</a></li>
    </ul> -->
    <div class="links">
      <!-- <a href="https://www.youtube.com/channel/UC8xTWwTkqPLJd5PNN09MyHQ"
        ><i class="fab fa-youtube-square animate__animated animate__fadeIn animate__delay-1s"></i
      ></a> -->
      <a href="https://www.linkedin.com/in/matthew-david-percy/"
        ><i class="awesomefonts fab fa-linkedin animate__animated animate__fadeIn animate__delay-1s"></i
      ></a>
      <a href="Resume.pdf"
        ><i class="awesomefonts fa-solid fa-file-lines animate__animated animate__fadeIn animate__delay-1s"></i
      ></a>
      <!-- <a href="https://mrbru3.itch.io/"><i class="fab fa-itch-io"></i></a> -->
      <a href="https://github.com/matthew-percy">
        <i class="awesomefonts fab fa-github-square animate__animated animate__fadeIn animate__delay-1s"></i
      ></a>
    </div>
  </main>
</template>    
<script>
// import 'animate.css';

export default {
  methods: {
    smoothScrollToSection(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      });

    },
  },
};
</script>



<style scoped>
/* h1{
  margin-top: auto;
  margin-bottom: 2vh;
}
.links { margin-bottom: auto;} */
#TopSection {
  overflow: hidden;
}
span {
  display: inline-block;
}
ul {
  padding-left: 30px;
  padding-right: 30px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: calc(1.5vw + 16px) 0px;
}
ul li {
  background-color: rgba(255, 255, 255, 0.5);
  /* background-image: linear-gradient(45deg, rgba(106, 140, 231,0.2) 0%, rgba(106, 140, 231,0.2) 14.286%,rgba(90, 125, 212,0.2) 14.286%, rgba(90, 125, 212,0.2) 28.572%,rgba(42, 81, 156,0.2) 28.572%, rgba(42, 81, 156,0.2) 42.858%,rgba(26, 67, 138,0.2) 42.858%, rgba(26, 67, 138,0.2) 57.144%,rgba(10, 52, 119,0.2) 57.144%, rgba(10, 52, 119,0.2) 71.43%,rgba(74, 111, 194,0.2) 71.43%, rgba(74, 111, 194,0.2) 85.716%,rgba(58, 96, 175,0.2) 85.716%, rgba(58, 96, 175,0.2) 100.002%),linear-gradient(45deg, rgba(106, 140, 231,0.2) 0%, rgba(106, 140, 231,0.2) 14.286%,rgba(90, 125, 212,0.2) 14.286%, rgba(90, 125, 212,0.2) 28.572%,rgba(42, 81, 156,0.2) 28.572%, rgba(42, 81, 156,0.2) 42.858%,rgba(26, 67, 138,0.2) 42.858%, rgba(26, 67, 138,0.2) 57.144%,rgba(10, 52, 119,0.2) 57.144%, rgba(10, 52, 119,0.2) 71.43%,rgba(74, 111, 194,0.2) 71.43%, rgba(74, 111, 194,0.2) 85.716%,rgba(58, 96, 175,0.2) 85.716%, rgba(58, 96, 175,0.2) 100.002%),linear-gradient(135deg, rgba(106, 140, 231,0.2) 0%, rgba(106, 140, 231,0.2) 14.286%,rgba(90, 125, 212,0.2) 14.286%, rgba(90, 125, 212,0.2) 28.572%,rgba(42, 81, 156,0.2) 28.572%, rgba(42, 81, 156,0.2) 42.858%,rgba(26, 67, 138,0.2) 42.858%, rgba(26, 67, 138,0.2) 57.144%,rgba(10, 52, 119,0.2) 57.144%, rgba(10, 52, 119,0.2) 71.43%,rgba(74, 111, 194,0.2) 71.43%, rgba(74, 111, 194,0.2) 85.716%,rgba(58, 96, 175,0.2) 85.716%, rgba(58, 96, 175,0.2) 100.002%),linear-gradient(90deg, rgb(21,114,187),rgb(18,91,148)); */
  text-shadow: 3px 3px 0px black;
  box-shadow: 0px 0px 30px black;
  border-radius: 15px;
  transition: 0.3s;

  border-radius: 3vw;
  /* display: block; */
  margin: 1.5vw 0px;

  flex: 1 0 auto;
}
/* .aboutList {
  margin-top: 3vw;
  margin-bottom: 1.5vw ;
}
.projectList {
  margin-top: 1.5vw;
} */

ul li a {
  color: white;
  text-decoration: none;
  transition: 0.3s;

  display: block;
  /* padding: 30px 30px; */
  font-size: 7vw;
  padding: 1.5vh 5vw; /*  padding: 4vw 5vw;*/ /*2.5vh 5vw*/
  text-shadow: 0.5vw 0.5vw 0px black;
  cursor: pointer;
}
.links {
  margin-top: 5vw;
}
.awesomefonts {
  color: white;
  text-shadow: 0px 0px 30px black;
  transition: 0.3s;

  font-size: 17vw; /* 15vw; */
  margin: 0px 3vw;
}
/* .fab {
  color: white;
  text-shadow: 0px 0px 30px black;
  transition: 0.3s;

  font-size: 17vw;
  margin: 0px 3vw;
} */
ul li:hover {
  background-color: rgba(255, 255, 255, 0.75);
}
.awesomefonts:hover {
  color: rgba(150, 150, 150, 1);
}
/* .fab:hover {
  color: rgba(150, 150, 150, 1);
} */
/* h1 {
  font-size: 175px;
  text-shadow: 5px 5px black;
  margin: 0px;
  padding-top: 10vh; 
}
ul {
  padding-left: 30px;
  padding-right: 30px;
  list-style-type: none;
}

ul li {
  background-color: rgba(255, 255, 255, 0.5);
  text-shadow: 3px 3px 0px black;
  box-shadow: 0px 0px 30px black;
  border-radius: 15px;
  transition: 0.3s;
  display: inline-block;
  margin: 2%;
}
ul li:hover {
  background-color: rgba(255, 255, 255, 0.75);
}

ul li a {
  min-width: 135px;
  display: inline-block;
  padding: 30px 13vw;
  font-size: 1em;

  color: white;
  text-decoration: none;
  transition: 0.3s;
}

.fab {
  color: white;
  font-size: 180px;
  margin: 0px 25px;

  margin: 0px 25px;
  text-shadow: 0px 0px 30px black;
  transition: 0.3s;
}
.links a {
  color: white;
  text-decoration: none;
  transition: 0.3s;
  margin: 0px;
  padding: 0px;
}
.fab:hover {
  color: rgba(150, 150, 150, 1);
} */
@media (orientation: landscape) {
  ul {
    padding-left: 15vh; /*1vh;*/
    padding-right: 15vh; /*1vh;*/
    list-style-type: none;
    flex-direction: row;
  }
  ul li {
    border-radius: 1.5vh;
    padding: 0px;
    margin: 1vh 3vh;
  }
  ul li a {
    min-width: 30vw; /* 50vh */
    padding: 1vh 5vh; /*4vh 5vh*/
    font-size: 7vh;
    text-shadow: 0.4vh 0.4vh 0px black;
  }
  .links {
    margin-top: 50px;
  }

  .awesomefonts {
    font-size: 20vh;
    margin: 3vh 3vh;
  }
  /* .fab {
    font-size: 20vh;
    margin: 3vh 3vh;
  } */
}

/*called when width is below 1000px */
/* @media ((max-width: 1000px) and (orientation: portrait)) { */
/* @media (orientation: portrait) {
  h1 {
    font-size: 20vw; 
    text-shadow: 0.5vw 0.5vw black;
  }
  ul li {
    border-radius: 3vw;
    display: block;
    margin: 3vw 0px;
  }

  ul li a {
    display: block;
    font-size: 7vw;
    padding: 2.5vh 5vw;
    text-shadow: 0.5vw 0.5vw 0px black;
  }
  .links {
    margin-top: 5vw;
  }
  .fab {
    font-size: 17vw;
    margin: 0px 3vw;
  }
} */
</style>