import { createRouter, createWebHistory } from 'vue-router';

import Home from '../views/home.vue';
import Cryptachi from '../views/cryptachi.vue'
// import Services from '../views/services.vue';


// import Portfolio from '../views/portfolio.vue';
// import Testimonials from '../views/testimonials.vue';
// import BookACall from '../views/book-a-call.vue';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior: (to) => {
    const scrollToOptions = {};

    if (to.hash) {
      scrollToOptions.el = to.hash;
      // scrollToOptions.behavior = "smooth"
    } else {
      scrollToOptions.top = 0;
      // scrollToOptions.behavior = "auto"
    }
    // scrollToOptions.behavior = "auto"
    return scrollToOptions;
  },
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },
    {
      path: '/cryptachi',
      name: 'Cryptachi',
      component: Cryptachi,
    },
    // { path: '/:catchAll(.*)', component: Home }
    {
      path: '/:catchAll(.*)',
      redirect: '/'
    }
    // {
    //   path: '/portfolio',
    //   name: 'Portfolio',
    //   component: Portfolio,
    // },
    // {
    //   path: '/testimonials',
    //   name: 'Testimonials',
    //   component: Testimonials,
    // },
    // {
    //   path: '/book-a-call',
    //   name: 'BookACall',
    //   component: BookACall,
    // },
  ],
});

export default router;
