<template>
    <main-section />
    <!-- <a class="returnButton" v-show="scY >1000" href="#Top"><i class="fas fa-angle-up fab"></i></a> -->
</template>

<script>
import MainSection from '../components/MainSection.vue';

export default {
  name: 'App',
  components: {
    MainSection,
  },
};
</script>

<style scoped>
</style>
