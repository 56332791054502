<template>
  <main>
    <h2>{{ title }}</h2>
    <div class="projectView" :style="projectView">
      <div class="projectViewPictures" :style="projectViewPictures">
        <vueper-slides
          autoplay
          fade
          class="no-shadow"
          :infinite="false"
          :visible-slides="1"
          :gap="5"
          :dragging-distance="70"
          :touchable="true"
          :slide-ratio="9 / 16"
        >
          <!-- fixed-height="true" -->
          <!-- <vueper-slide /> -->
          <vueper-slide
            v-for="(slide, i) in images"
            :key="i"
            :image="slide"
            :title="slide.title"
            :content="slide.content"
          />
          <!-- :style="{ 'background-image': 'url(@/assets/logoV.png)' }" -->
        </vueper-slides>
        <div
          class="projectViewButtons"
          v-if="isLandscape"
          :style="projectViewButtons"
        >
          <a v-on:click="returnToHome()" :style="projectViewButtonsA"
            >View other Projects</a
          >
          <a :href="link" :style="projectViewButtonsA">View on Github</a>
        </div>
      </div>

      <div class="projectViewDescription" :style="projectViewDescription">
        {{ description }}
        <!-- {{ projectDescriptions[selectedIndex] }} -->
      </div>
      <div
        class="projectViewButtons"
        v-if="!isLandscape"
        :style="projectViewButtons"
      >
        <!-- <div class="returnButton"> </div>
          <div class="linkButton"> </div> -->
        <a :href="link">View on Github</a>

        <a v-on:click="returnToHome()">View other Projects</a>
        <!-- <ul>
            <li><a v-on:click="exitCarouselle">Return</a></li>
            <li><a href="#Projects">Projects</a></li>
          </ul> -->
      </div>
    </div>
  </main>
  <footer></footer>
</template>


<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';

export default {
  props: ['title', 'images', 'description', 'link'],
  data: () => ({
    projectSlides: [
      [
        {
          image: require('../assets/CodeVanguards/logoV.png'),
        },
        {
          image: require('../assets/CodeVanguards/shop-mockup.png'),
        },
        {
          image: require('../assets/CodeVanguards/achievements-mockup.png'),
        },
        {
          image: require('../assets/CodeVanguards/tasks.png'),
        },
        {
          image: require('../assets/CodeVanguards/statistics-mockup.png'),
        },
      ],

      [
        {
          image: require('../assets/OneBullet/THUMBNAILnotext.png'),
        },
      ],

      [],

      [],

      [],
    ],
    test: 'red',
    test2: {},
    projectView: {},
    projectViewPictures: {},
    projectViewDescription: {},
    projectViewButtons: {},
    projectViewButtonsA: {},
    isLandscape: false,
  }),
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeMount() {
    this.onResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      // if (window.innerWidth > window.innerHeight) {
      if (window.innerHeight / window.innerWidth <= 0.75) {
        if (!this.isLandscape) {
          this.projectView = {
            'flex-direction': 'row',
            'flex-wrap': 'wrap',
            'align-items': 'center',
          };
          this.projectViewPictures = {
            flex: '0 10 50vw',
            'margin-bottom': '0',
          };
          this.projectViewDescription = {
            'text-shadow': '0.3vh 0.3vh black',
            margin: '0 5vw 0 0',
            flex: '100 100 20vw',
          };
          this.projectViewButtons = {
            margin: '1.5vw 0',
            'flex-direction': 'row',
          };
          this.projectViewButtonsA = {
            padding: '1.5vh 2.5vw',
            'text-shadow': '0.3vh 0.3vh black',
          };
        }
        this.isLandscape = true;
      } else {
        if (this.isLandscape) {
          this.projectView = {};
          this.projectViewPictures = {};
          this.projectViewDescription = {};
          this.projectViewButtons = {};
          this.projectViewButtonsA = {};
        }
        this.isLandscape = false;
      }
    },
    returnToHome() {
      // this.$router.push('/');
      this.$router.push('/#Projects');
    },
  },
  components: {
    VueperSlides,
    VueperSlide,
  },
};
</script>



<style scoped>
a {
  cursor: pointer;
  color: white;
  text-decoration: none;
  transition: 0.3s;
}
a:hover {
  background-color: rgba(255, 255, 255, 0.75);
}
main {
  /* background-image: repeating-linear-gradient(405deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(315deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(67.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(405deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(315deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(112.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(112.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(315deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(22.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(315deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(22.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(405deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(157.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(67.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),repeating-linear-gradient(67.5deg, hsla(264,0%,88%,0.03) 0px, hsla(264,0%,88%,0.03) 1px,transparent 1px, transparent 12px),linear-gradient(360deg, rgb(151, 26, 210),rgb(57, 199, 205)); */
  /* background-image: url('../assets/CodeVanguards/logoV.png'); */
  background-image: repeating-linear-gradient(
      405deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      315deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      67.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      405deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      315deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      112.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      112.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      315deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      22.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      315deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      22.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      405deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      157.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      67.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      67.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    linear-gradient(360deg, rgb(126, 195, 9), rgb(22, 94, 235));
  background-image: repeating-linear-gradient(
      225deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      67.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      225deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      112.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      112.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      22.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      135deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      22.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      225deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      157.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      67.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    repeating-linear-gradient(
      67.5deg,
      hsla(264, 0%, 88%, 0.03) 0px,
      hsla(264, 0%, 88%, 0.03) 1px,
      transparent 1px,
      transparent 12px
    ),
    linear-gradient(180deg, rgb(22, 94, 235), rgb(57, 199, 205));
}

.vueperslide {
  box-shadow: 0px 0px 15px black;
  overflow: visible;
  border-radius: 3vw; /*50px;*/
  /* background-blend-mode: darken; */
  background-color: grey; /*rgb(221, 215, 215);*/
  /* transition: 0.3s; */
}

.projectView {
  flex-direction: column;
}
.projectViewPictures {
  margin: 5vw 5vw 5vw 5vw;
  order: 0;
}
.projectView .vueperslides--fixed-height {
  height: 40vh;
}
.projectViewDescription {
  margin: 0px 5vw;
  font-size: 2.5vh;
  order: 1;
}
/* .projectViewButtons * {
  margin: 25px auto;
  padding: 15px 1vw;
  width: 30vw;
} */
.projectView {
  display: flex;
}
.projectViewButtons * {
  /* display: block; */
  /* font-size: 25px; */
  /* margin: 25px auto; */
  /* padding: 15px 1vw; */
  padding: 1.5vh 5vw;
  margin: 1.5vw;
  font-size: 3vh;
  text-shadow: 0.3vw 0.3vw black;
  background-color: rgba(255, 255, 255, 0.5);
  /* text-shadow: 3px 3px 0px black; */
  box-shadow: 0px 0px 30px black;
  /* border-radius: 15px; */
  border-radius: 1.5vh;
  /* flex: 1 1 0px; */
  width: 50%;
  /* flex-grow: 0; */
  /* width: 300px; */
  /* max-width: 50%; */
  /* width: 15vw; */
}
.projectViewDescription {
  font-size: 3vh;
  text-shadow: 0.3vw 0.3vw black;
}
.projectView .vueperslides {
}
.projectView .vueperslide {
}
.projectViewButtons {
  margin: 5vw 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  order: 2;
}
@media (orientation: landdscape) {
  .projectView {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .projectViewPictures {
    flex: 0 10 50vw;
  }
  .projectViewDescription {
    text-shadow: 0.3vh 0.3vh black;
    margin: 5vw 5vw 0 0;
    flex: 100 100 20vw;
  }
  .projectViewButtons {
    margin: 1.5vw 0;
    flex-direction: row;
  }
  .projectViewButtons * {
    padding: 1.5vh 2.5vw;
    text-shadow: 0.3vh 0.3vh black;
  }
}

/* @media (orientation: landscape) {
  .projectViewPictures {
    margin: 0 5vw;
  }
  .projectViewDescription {
    margin-right: 5vw;
    font-size: 2.5vh;
    text-shadow: 0.15vh 0.15vh 0px black;
  }
  .projectView {
    display: flex;
  }
  .projectView .vueperslides {
    width: 50vw;
  }
  .projectView .vueperslides--fixed-height {
    height: 50vh;
  }
} */
</style>
