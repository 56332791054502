<template>
  <project-view title="Cryptachi" link="https://www.youtube.com/feed/subscriptions" :images="images" :description="description" />
  <!-- <projects-section /> -->

  <!-- <footer></footer> -->
  <!-- <scroll-to-top /> -->
  <!-- <a class="returnButton" v-show="scY >1000" href="#Top"><i class="fas fa-angle-up fab"></i></a> -->
</template>

<script>
// import MainSection from '../components/MainSection.vue';
// import ProjectsSection from '../components/ProjectsSection.vue';
// import AboutSection from '../components/AboutSection.vue';
// import ScrollToTop from '../components/scrollToTop.vue';
import ProjectView from '../components/ProjectView.vue';

export default {
  name: 'App',
  data: () => ({
    images: {
      image: require('../assets/CodeVanguards/logoV.png'),
      image2: require('../assets/CodeVanguards/shop-mockup.png'),
    },
    description: `Launched an online service which turns forgettable cryptocurrency addresses into easy to remember domain names
Brainstormed and researched the possibility of business idea
Plan and implement API endpoints such as the login, registration, security, user functionality, and payment systems to handle requests from web client
Setup and maintain MongoDB database to store data of website
Saved 3% in transaction fees and 10% in withdrawal fees by deploying a cryptocurrency payment gateway
Assist in production release of website and payment gateway using local infrastructure saving up to $250 per month in VPS subscription fees
`
  }),

  components: {
    // MainSection,
    // ProjectsSection,
    // AboutSection,
    // ScrollToTop,
    ProjectView,
  },
};
</script>

<style scoped>
</style>
